<template>
    <div class="badges__form">
        <div class="label">
            <p class="form__label">Language</p>
            <div style="display: flex; justify-content: space-between">
                <locale-picker
                    v-model="chosenLang"
                    value-range="lang-only"
                    :show-label="false"
                    @input="handleLangChange()"
                />
                <a-button
                    v-if="!hideResetBtn && !showLangChangeOnly"
                    @click="handleBadgesReset()"
                >
                    <a-icon type="undo" />
                    Rest badges
                </a-button>
            </div>
            <p class="form__label badges__label" v-if="!showLangChangeOnly">
                Badges
            </p>
            <a-select
                v-if="!showLangChangeOnly"
                class="label"
                v-model="chosenBadgesIds"
                mode="multiple"
                placeholder="Select badges..."
                @change="handleBadgesChange()"
            >
                <template #dropdownRender="menu">
                    <div class="badges__form">
                        <div class="label">
                            <v-nodes :vnodes="menu" />
                        </div>
                    </div>
                </template>
                <a-select-option
                    v-for="badge in badgesList"
                    :key="badge.id"
                    :value="badge.id"
                >
                    <div v-html="makeBadge(badge)"></div>
                </a-select-option>
            </a-select>
        </div>
    </div>
</template>

<script>
import LocalePicker from '@/components/shared/locale-picker/LocalePicker'
import {
    makeStandardBadge,
    makeDiscountBadge,
} from '@/templating/badge-rederer'
import { mapActions } from 'vuex'
import {
    SPECIAL_ID,
    SPECIAL_TYPE,
    MAX_BADGES_ON_CREATION,
} from '../../model/special-badges'

const filterAlreadyPresentDiscountBadges = (b) =>
    ![SPECIAL_ID.DISCOUNT, SPECIAL_ID.DISCOUNT_IN_BULK].includes(b.id)

export default {
    name: 'FormBadges',
    components: {
        LocalePicker,
        VNodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes,
        },
    },
    props: {
        currentBadges: Array,
        possibleBadges: Array,
        discountBadgeData: Object,
        lang: {
            type: String,
            default: 'pl',
        },
        hideResetBtn: {
            type: Boolean,
            default: true,
        },
        showLangChangeOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            chosenBadgesIds: [],
            buyInBulk: false,
            badgesToSend: [],
            chosenLang: 'pl',
            hasReset: false,
        }
    },
    computed: {
        discountBadge() {
            return {
                id: SPECIAL_ID.DISCOUNT,
                label: this.discountBadgeData?.label || 0,
                label_two: this.discountBadgeData?.label_two || 0,
                type: SPECIAL_TYPE.DISCOUNT,
            }
        },
        discountInBulkBadge() {
            return {
                ...this.discountBadge,
                id: SPECIAL_ID.DISCOUNT_IN_BULK,
            }
        },
        badgesList() {
            const hasDiscount = Boolean(this.discountBadgeData)
            return [
                ...(hasDiscount
                    ? [this.discountBadge, this.discountInBulkBadge]
                    : []),
                ...this.possibleBadges.filter(
                    filterAlreadyPresentDiscountBadges
                ), // Discount might be already possible - avoid duplication!
            ]
        },
    },
    methods: {
        ...mapActions(['showAlertError', 'showAlertSuccess']),
        makeBadge(badge) {
            if (badge.type === SPECIAL_TYPE.DISCOUNT) {
                return makeDiscountBadge(badge, this.chosenLang)
            }
            return makeStandardBadge(badge)
        },
        handleLangChange() {
            this.$emit('langChange', {
                action: 'lang',
                lang: this.chosenLang,
                badges: this.badgesToSend,
            })
        },
        discountLogicGuard(currentValue) {
            /*
             * There should be only one discount badge selected:
             * */
            if (currentValue.length > 1) {
                const last = currentValue[currentValue.length - 1]
                const oldValue = currentValue.slice(0, -1)
                if (
                    last === SPECIAL_ID.DISCOUNT_IN_BULK &&
                    oldValue.includes(SPECIAL_ID.DISCOUNT)
                ) {
                    return currentValue.filter((v) => v !== SPECIAL_ID.DISCOUNT)
                }
                if (
                    last === SPECIAL_ID.DISCOUNT &&
                    oldValue.includes(SPECIAL_ID.DISCOUNT_IN_BULK)
                ) {
                    return currentValue.filter(
                        (v) => v !== SPECIAL_ID.DISCOUNT_IN_BULK
                    )
                }
            }
            return currentValue
        },
        handleBadgesChange() {
            this.chosenBadgesIds = this.discountLogicGuard(this.chosenBadgesIds)
            if (this.chosenBadgesIds.length > MAX_BADGES_ON_CREATION) {
                this.chosenBadgesIds.pop()
                this.showAlertError(
                    `Select only ${MAX_BADGES_ON_CREATION} badges`
                )
                return
            }

            // @Fix: Map id to preserve "clicking" order
            const chosenIdMapper = [
                ...this.possibleBadges,
                this.discountBadge,
                this.discountInBulkBadge,
            ].reduce((idMapper, badge) => {
                if (this.chosenBadgesIds.includes(badge.id)) {
                    idMapper[badge.id] = badge
                }
                return idMapper
            }, {})
            this.badgesToSend = this.chosenBadgesIds
                .map((id) => chosenIdMapper[id])
                .filter(Boolean)
                .reverse()
            // #reverse to make proper order when .prepend on <div> box.

            this.$emit('change', { action: 'list', badges: this.badgesToSend })
        },
        handleBadgesReset() {
            this.hasReset = true
            this.$emit('reset')
        },
    },
    watch: {
        lang: {
            immediate: true,
            handler(currentValue) {
                this.chosenLang = currentValue
            },
        },
        currentBadges: {
            immediate: true,
            handler(currentValue, oldValue) {
                if (
                    this.hasReset ||
                    !oldValue ||
                    (Array.isArray(oldValue) && oldValue.length === 0)
                ) {
                    // #Fix: clear badgesToSend before modify ! -> resolves "Double-badge BUG"
                    this.badgesToSend = []
                    if (this.hasReset) {
                        // #Crucial to control when RESET event happened - for proper control updates!
                        this.chosenBadgesIds = []
                    }
                    for (const badge of this.currentBadges || []) {
                        this.badgesToSend.push(badge)
                        if (!this.chosenBadgesIds.includes(badge.id)) {
                            this.chosenBadgesIds.push(badge.id)
                        }
                    }
                    // #reverse to make proper oder inside <a-select>...
                    this.chosenBadgesIds.reverse() // ...and to match case from: handleBadgesChange()
                    this.hasReset = false
                }
            },
        },
    },
}
</script>

<style lang="less">
.badges__label {
    margin-top: 0.5em;
}

.ant-select-dropdown-menu .badges__item {
    width: fit-content;
}
</style>
