import { PossibleValuesProvider } from '@/templating/values/PossibleValuesProvider'

const DEFAULT_LANG = 'pl'

export class TControl {
    static EVENT_TYPES = {
        DATA_CHANGED: 'data-changed',
        LANG_CHANGED: 'lang-changed',
    }

    constructor(ref, type) {
        this.ref = ref
        this.type = type
        this.isReactive = false
        this.langToken = DEFAULT_LANG
        this.possibleValuesProvider = new PossibleValuesProvider(ref)
        this.updatePossibleValues()
    }

    // public API for Grouped dispatches.
    makeEventId() {
        return crypto.randomUUID()
    }

    // eventId is needed to revert some of the "grouped" actions (dispatches)
    dispatch(id, key, value, eventId = this.makeEventId()) {
        const detail = { type: this.type, id, key, value, eventId }
        this.ref.dispatchEvent(
            new CustomEvent(TControl.EVENT_TYPES.DATA_CHANGED, {
                bubbles: true,
                detail,
            })
        )
    }

    setupReactivity() {
        this.isReactive = true
    }

    changeLanguage(lang = DEFAULT_LANG) {
        this.langToken = lang
        this.updatePossibleValues()
        // @Business (fix): lang must be present at element to apply per lang CSS in label.less file
        this.ref.dataset.lang = lang
        this.ref.dispatchEvent(
            new CustomEvent(TControl.EVENT_TYPES.LANG_CHANGED, {
                bubbles: true,
                detail: this.langToken,
            })
        )
    }

    updatePossibleValues() {
        this.possibleValues = this.possibleValuesProvider.computeValues(
            this.langToken
        )
    }

    onDestroy() {}

    toJSON() {
        // This is getting data ONLY from getters + remove Vue.observable bindings
        const prototype = Object.getPrototypeOf(this)
        return {
            // Check getters in prototype:
            ...extractGettersValue(prototype, this),
            // Check getters in instance object:
            ...extractGettersValue(this, this),
        }
    }

    smartUpdate(callbackRefresh) {
        // smartUpdate refreshes 4x in separate ways for "Smooth" transition + proper size and position etc.
        callbackRefresh()
        setTimeout(callbackRefresh)
        // only non-reactive element need to be refreshed more times
        if (!this.isReactive) {
            // there has to be some time for proper update (refresh)
            setTimeout(callbackRefresh, 300)
            setTimeout(callbackRefresh, 500)
        }
    }
}

function extractGettersValue(obj, caller) {
    const allValues = {}
    for (const key of Object.getOwnPropertyNames(obj)) {
        const descriptor = Object.getOwnPropertyDescriptor(obj, key)
        const hasGetter = descriptor && typeof descriptor.get === 'function'
        if (hasGetter) {
            allValues[key] = descriptor.get.call(caller)
        }
    }
    return allValues
}
